<template>
  <div class="container-fluid">
    <div class="row card">
      <div class="col-md-12 my-half px-half">
        <div class="d-flex">
          <div class="mr-half">
            <dx-util-select-box
              v-model="selectedStore"
              :data-source="stores"
              display-expr="text"
              value-expr="value"
              @value-changed="searchByFilter"
            />
          </div>
          <date-selection default-date="last30Days" @setdates="onDateSelection" />
        </div>
      </div>
      <div class="col-md-12 px-0">
        <DxPivotGrid
          id="sales"
          ref="salesGridRef"
          height="calc(100vh - 200px"
          :allow-sorting-by-summary="true"
          :allow-sorting="true"
          :allow-filtering="true"
          :allow-expand-all="true"
          :show-borders="true"
          :data-source="dataSource"
          :on-cell-prepared="onCellPrepared"
          >
          <DxFieldChooser :enabled="false" />
        </DxPivotGrid>
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import DateSelection from '@/views/dashboard/statistics/components/DateSelection.vue'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import AmazonSalesFilter from '@/http/models/search-filters/AmazonSalesFilter'
import DxPivotGrid, { DxFieldChooser } from 'devextreme-vue/pivot-grid'
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source'
import CustomStore from 'devextreme/data/custom_store'

export default {
  components: {
    'date-selection': DateSelection,
    DxPivotGrid,
    DxFieldChooser,
  },
  mixins: [GridBase, Filters],
  data() {
    return {
      datasourceGrid: [],
      beginDate: null,
      endDate: null,
      query: '',
      dataSource: new PivotGridDataSource({
        fields: [
          {
            caption: 'Group',
            width: 120,
            dataField: 'group',
            area: 'row',
          },
          {
            caption: 'Sub Group',
            dataField: 'subGroup',
            width: 150,
            area: 'row',
            selector(data) {
              return `${data.subGroup}  `
            },
          },
          {
            caption: 'Item',
            dataField: 'item',
            width: 150,
            area: 'row',
            selector(data) {
              return `${data.item}  `
            },
          },
          {
            dataField: 'dateGroup',
            dataType: 'date',
            area: 'column',
            groupInterval: 'year',
          },
          {
            dataField: 'dateGroup',
            dataType: 'date',
            area: 'column',
            groupInterval: 'quarter',
          },
          {
            dataField: 'dateGroup',
            dataType: 'date',
            area: 'column',
            groupInterval: 'month',
          },
          {
            caption: 'Sales',
            dataField: 'amount',
            dataType: 'number',
            summaryType: 'sum',
            format: 'currency',
            area: 'data',
          },
        ],
        store: new CustomStore({
          load: () => this.getIncomeStatementData(this.optionsFilter),
        }),
      }),
    }
  },
  computed: {
    dataSourceRef() {
      const datagrid = this.$refs.salesGridRef.instance
      return datagrid.getDataSource()
    },
    optionsFilter() {
      AmazonSalesFilter.setDefaultFilters()
      AmazonSalesFilter.dashboardPeriodType = null
      AmazonSalesFilter.beginDate = this.beginDate
      AmazonSalesFilter.endDate = this.endDate
      AmazonSalesFilter.storeId = this.selectedStore
      return AmazonSalesFilter.getFilters()
    },
  },
  created() {
    this.getStores()
  },
  methods: {
    searchByFilter() {
      this.dataSourceRef.reload()
    },
    onDateSelection(data) {
      this.beginDate = data.beginDate
      this.endDate = data.endDate
      this.searchByFilter()
    },
    async getIncomeStatementData(filters) {
      return amazonReportService.dashboardIncomeStatement(filters)
    },
    onCellPrepared(e) {
      if (e.area === 'data' && e.cell.value < 0) {
        e.cellElement.style.color = 'red'
      }
    },
  },
}
</script>

<style lang="scss">
</style>
